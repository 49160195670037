@import "~antd/dist/antd.css";
$primary: #1890ff;
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #cdcdcd;
}
.ant-steps-item-title::after {
  background: #cdcdcd;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #cdcdcd;
}
.ant-input-group-addon {
  padding: 0;
  border: 0;
  .verificationCode {
    color: #1890ff;
    border: 1px solid #1890ff;
    background: #edf3ff;
  }
}

.subjectPromote_container .payType .ant-checkbox-checked::after {
  border: none;
}
.verificationCode {
  color: #1890ff;
  border: 1px solid #1890ff;
  background: #edf3ff;
}

#root {
  overflow-x: hidden;
}
* {
  -webkit-font-smoothing: antialiased;
}
ul {
  list-style: none;
}
.container-1200 {
  width: 1200px;
  margin: 0 auto;
}
.gray {
  color: #666666;
}
.f12 {
  font-size: 12px;
}
.btn-primary-bg {
  border: 1px solid #1890ff;
  background: #edf3ff;
  color: #1890ff;
}
.btn-primary-border {
  border: 1px solid #1890ff;
  color: #1890ff;
  border-radius: 4px;
}
.btn-danger-border {
  border: 1px solid #b52e25;
  color: #b52e25;
  border-radius: 4px;
}
.textRight {
  text-align: right;
}
.textCenter {
  text-align: center;
}
.read_only {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.8;
}
.customize-btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    border: 1px solid #1890ff;
    color: #1890ff;
    width: 32px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
  }

  .disable {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.8;
  }
}
.cke_notifications_area{
  display: none !important;
}
