.serviceProviderApply-container {
  min-width: 1300px;
  max-width: 2000px;
  margin: 0 auto;
  .serviceProviderApply-content {
    z-index: 0;
    position: relative;
    background: cornflowerblue;
    padding-top: 80px;
    .serviceProviderApply-main {
      background: white;
      .serviceProviderApply-header {
        padding: 60px 0 30px;
        color: #1890ff;
        font-size: 22px;
        font-weight: bold;
        border-bottom: 2px solid #1890ff;
      }
      .serviceProviderApply-form-item {
        margin-top: 30px;
        h3 {
          height: 54px;
          line-height: 54px;
          background: #ebf2ff;
          color: #1890ff;
          font-size: 18px;
          font-weight: bold;
          padding-left: 20px;
          margin-bottom: 30px;
        }
        .radioStyle {
          line-height: 35px;
        }
        .radioBlockStyle {
          display: block;
        }
      }
      .handle_submit {
        text-align: center;
        margin: 100px auto;
        button {
          width: 186px;
          height: 40px;
          border-radius: 4px;
        }
      }
      .bankStyle {
        .ant-form-item-label {
          max-width: 146px;
        }
      }
    }
  }
}
